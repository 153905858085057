<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6 lg:col-5">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <div class="text-2xl text-primary font-bold">
                      Edit Blockedusers
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="md:col-9 sm:col-12 comp-grid">
            <div :class="{ card: !isSubPage }" class="">
              <div>
                <template v-if="pageReady">
                  <div class="grid">
                    <div class="col-12">
                      <form
                        ref="observer"
                        tag="form"
                        @submit.prevent="submitForm()"
                      >
                        <!--[form-content-start]-->
                        <div class="grid">
                          <div class="col-12">
                            <div class="formgrid grid">
                              <div class="col-12 md:col-3">Msisdn *</div>
                              <div class="col-12 md:col-9">
                                <InputText
                                  ref="ctrlmsisdn"
                                  v-model.trim="formData.msisdn"
                                  label="Msisdn"
                                  type="text"
                                  placeholder="Enter Msisdn"
                                  class="w-full"
                                  :class="getErrorClass('msisdn')"
                                >
                                </InputText>
                                <small
                                  v-if="isFieldValid('msisdn')"
                                  class="p-error"
                                  >{{ getFieldError("msisdn") }}</small
                                >
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="formgrid grid">
                              <div class="col-12 md:col-3">Reason</div>
                              <div class="col-12 md:col-9">
                                <Textarea
                                  :class="getErrorClass('reason')"
                                  class="w-full"
                                  ref="ctrlreason"
                                  rows="5"
                                  v-model="formData.reason"
                                  placeholder="Enter Reason"
                                  type="textarea"
                                >
                                </Textarea>
                                <small
                                  v-if="isFieldValid('reason')"
                                  class="p-error"
                                  >{{ getFieldError("reason") }}</small
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--[form-content-end]-->
                        <div v-if="showSubmitButton" class="text-center my-3">
                          <Button
                            type="submit"
                            label="Update"
                            icon="pi pi-send"
                            :loading="saving"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </template>
                <template v-if="loading">
                  <div class="p-3 text-center">
                    <ProgressSpinner style="width: 50px; height: 50px" />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
//[--PAGE-IMPORT-STATEMENT--]
import { PageMixin } from "../../mixins/page.js";
import { EditPageMixin } from "../../mixins/editpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "editBlockedusersPage",
  components: {
    //[--PAGE-COMPONENT-NAME--]
  },
  mixins: [PageMixin, EditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "blockedusers",
    },
    idName: {
      type: String,
      default: "recordid",
    },
    routeName: {
      type: String,
      default: "blockedusersedit",
    },
    pagePath: {
      type: String,
      default: "blockedusers/edit",
    },
    apiPath: {
      type: String,
      default: "blockedusers/edit",
    },
  },
  data() {
    return {
      formData: {
        msisdn: "",
        reason: "",
      },
      submitted: false,
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Edit Blocked User";
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["blockedusers/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("blockedusers/setCurrentRecord", value);
      },
    },
  },
  validations() {
    let formData = {
      msisdn: { required },
      reason: {},
    };
    return { formData };
  },
  methods: {
    ...mapActions("blockedusers", ["updateRecord", "fetchRecord"]),
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Please complete the form", "Form is invalid", "error");
        return;
      }
      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let payload = this.normalizedFormData();
      let data = { id, url, payload };
      this.updateRecord(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(this.msgAfterUpdate);
          this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
          if (this.redirect) this.navigateTo(`/blockedusers`);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
      this.formData.tag = this.$utils.toArray(this.formData.tag);
    },
    resetForm() {
      //reset form fields value
      this.formData = {
        msisdn: "",
        reason: "",
      };
      //raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
  },
  watch: {},
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
